<template>
  <div class="content block-el">
    <template v-if="fetched">
      <PageHeader :title="`${ticket.subject}`">
        <p class="p-support">
          <span>Ticket #{{ ticket.id | minifyUuid }}</span>
          <span>Aberto {{ ticket.created_at | formatDate24 }}</span>
        </p>
        <span class="badge badge-info to-right" v-if="ticket.status == 'open'"
          >{{$t('generic-str.status.lbl-open')}}</span
        >
        <span
          class="badge badge-danger to-right"
          v-else-if="ticket.status == 'closed'"
          >{{$t('generic-str.status.lbl-closed')}}</span
        >
        <span
          class="badge badge-dark to-right"
          v-else-if="ticket.status == 'archived'"
          >{{$t('generic-str.status.lbl-filed')}}</span
        >
        <router-link to="/admin/tickets"
          style="margin-top:-70px; position: absolute; right: 30px"
          class="btn btn-secondary"
        ><i class="fas fa-arrow-left color-white"></i> {{$t('generic-str.return')}}</router-link>
      </PageHeader>
      <div class="page-content container-fluid">
        <div class="row" v-if="ticket.status != 'archived'">
          <div class="col-md-12">
            <div class="card">
              <div class="card-body">
                <form action="#" class="form-horizontal">
                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <div class="form-group row">
                        <label class="control-label text-left col-3"
                          >{{$tc('generic-str.message', 1)}}</label
                        >
                        <div class="col-md-12">
                          <textarea
                            type="text"
                            v-model="form.message"
                            class="form-control"
                            rows="5"
                          ></textarea>
                        </div>
                        <div class="col-sm-12">
                          <div class="form-group row">
                            <label class="control-label text-left col-12"
                              >{{$t('generic-str.attach-file')}}</label
                            >
                            <div v-if="!importing" class="col-md-6">
                              <input
                                ref="uploadedFile"
                                type="file"
                                hidden
                                multiple
                                @change="uploadFile"
                              />
                              <div class="input-group mb-3">
                                <div class="form-control">{{ form.file }}</div>
                                <div class="input-group-append">
                                  <button
                                    class="btn btn-primary"
                                    type="button"
                                    @click="openFileUpload"
                                  >
                                    {{$t('sms.send-msg.tabs.archives.lbl-add-archive')}}
                                  </button>
                                </div>
                              </div>
                            </div>
                            <div v-else class="col-md-6">
                              <div class="row">
                                <div class="col-12">
                                  <small>{{$t('sms.send-msg.tabs.archives.lbl-validating')}}</small>
                                </div>
                              </div>
                              <div class="progress">
                                <div
                                  class="progress-bar progress-bar-striped progress-bar-animated"
                                  role="progressbar"
                                  :style="`width: ${uploadProgress}%;`"
                                  aria-valuenow="25"
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                >
                                  {{ uploadProgress }}%
                                </div>
                              </div>
                            </div>
                            <div class="col-md-12">
                              <div class="row" v-for="(attachment, i) of form.attachments" :key="i">
                                <div class="col-sm-6">
                                  <div class="card">
                                    <div class="row p10">
                                      <div class="col-sm-10" style="padding-top:10px;">
                                        <h6>{{attachment.name}}</h6>
                                      </div>
                                      <div class="col-sm-2">
                                        <button class="btn btn-danger w100" type="button" @click="removeFile(i, attachment.id)">
                                          <i class="fas fa-times color-white"></i>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form-group row">
                        <div class="col-sm-12 d-flex justify-content-between">
                          <button
                            type="button"
                            :class="{
                              'qt-loader qt-loader-mini qt-loader-right': isSending,
                            }"
                            :disabled="isSending"
                            class="btn btn-primary float-right"
                            @click="send()"
                          >
                            {{$t('generic-str.reply')}}
                          </button>
                          <button
                            v-if="ticket.status == 'open'"
                            type="button"
                            :class="{
                              'qt-loader qt-loader-mini qt-loader-right': isSending,
                            }"
                            :disabled="isSending"
                            class="btn btn-danger float-right"
                            @click="close()"
                          >
                            {{$t('generic-str.close')}}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="form-row">
            <div class="col-md-4 m-top-10">
              <div class="form-group">
                <h4>{{$tc('generic-str.message', 2)}}</h4>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-for="message in messages" :key="message.id">
          <div class="col-12">
            <div
              class="card border-top"
              :class="{
                'border-left-info': message.type == 'customer',
                'border-left-success': message.type == 'staff',
              }"
            >
              <div class="card-body">
                <h5 class="card-title">
                  {{ message.user.name }}
                </h5>
                <p class="card-text">{{ message.content }}</p>
                <p class="card-text">
                  <small class="text-muted">{{
                    message.created_at | formatDate24
                  }}</small>
                </p>
                <p v-if="message.attachment">
                  <span v-for="(message, i) in JSON.parse(message.attachment)" :key="i" style="margin-left: 5px">
                    <a :href="message.url" target="_blank" class="btn btn-info"><i class="fas fa-download color-white"></i> {{message.name}}</a>
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <div
      v-else
      class="static qt-block-ui"
      style="padding: 120px; margin-top: 15px"
    />
    <!-- <div
      v-else
      class="relative qt-block-ui"
      style="padding: 120px; margin-top: 15px"
    /> -->
  </div>
</template>

<script>
// @ is an alias to /src
import TicketService from '@/services/ticket.service';
import PageHeader from '@/components/PageHeader.vue';
import BucketService from '@/services/bucket.service';
// import moment from 'moment';

export default {
  name: 'SmsDashboard',
  components: {
    PageHeader,
  },
  data() {
    return {
      ticket: {},
      messages: [],
      options: {
        timePicker: false,
        autoApply: true,
        className: 'form-control',
        maxSpan: {
          days: 31,
        },
        locale: {
          format: 'DD/MM/YYYY',
          daysOfWeek: ['Do', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sa'],
          monthNames: [
            'Jan',
            'Fev',
            'Mar',
            'Abr',
            'Mai',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Otu',
            'Nov',
            'Dez',
          ],
        },
      },
      fetched: false,
      beginDate: '',
      endDate: '',
      form: {
        message: '',
        created_at: '',
        attachments: [],
      },
      isSending: false,
      pages: 1,
      loading: true,
      exporting: false,
      importing: false,
      uploadProgress: 0,
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    removeFile(i, id) {
      BucketService.deleteFile(id).then(
        () => {},
        (error) => {},
      );
      this.form.attachments.splice(i, 1);
    },
    openFileUpload() {
      this.$refs.uploadedFile.click();
    },
    callBackProgress(progress) {
      this.uploadProgress = progress;
    },
    uploadFile(event) {
      const self = this;
      const reader = new FileReader();
      this.importing = true;
      const formData = new FormData();
      const files = event.target.files;
      // this.form.file = files[0].name;
      const filesAr = [];
      files.forEach((file) => {
        filesAr.push(file);
        formData.append('files[]', file);
      });
      console.log(filesAr);
      // formData.append('files[]', filesAr);
      formData.append('role', 'public');
      formData.append('folder', 'ticket');

      Promise.all([
        BucketService.upload(
          formData,
          true,
          this.callBackProgress,
        ),
      ])
        .then(
          (responses) => {
            console.log(responses[0].data);
            responses[0].data.forEach((file) => {
              this.form.attachments.push(file);
            });
            this.$toast.show({
              title: this.$t('generic-str.success'),
              content: this.$t('warnings.send-component.success'),
              type: 'success',
            });
          },
          (error) => {
            self.form.file = '';
            self.$toast.show({
              title: 'Erro',
              content: error.response.data.message,
              type: 'danger',
            });
            this.importing = false;
          },
        )
        .finally(() => {
          this.importing = false;
          this.uploadProgress = 0;
        });
    },
    fetch() {
      this.fetched = false;
      TicketService.getAdminTicket(this.$route.params.id).then(
        (ticket) => {
          this.messages = ticket.messages;
          this.ticket = ticket;
          this.fetched = true;
        },
        (error) => {
          this.content = error;
          console.log(JSON.stringify(error.response.data));
        },
      );
    },
    info() {
      return this.$store.state.account;
    },
    close() {
      this.isSending = true;
      TicketService.closeAdminTicket(this.ticket.id)
        .then(
          () => {
            this.fetch();
            this.$toast.show({
              title: this.$t('generic-str.success'),
              content: this.$t('ticket-component.closed'),
              type: 'success',
            });
            this.isSending = false;
            this.$router.push('/admin/tickets');
          },
          (error) => {
            this.$toast.show({
              title: this.$t('sms.infos.filters.status.lbl-error'),
              content: error.response.data.message,
              type: 'danger',
            });
            this.isSending = false;
          },
        )
        .finally(() => {
          this.isSending = false;
        });
    },
    send() {
      if (this.form.message.length === 0) {
        this.$toast.show({
          title: this.$t('sms.infos.filters.status.lbl-error'),
          content: this.$t('ticket-component.category'),
          type: 'danger',
        });
        return;
      }

      this.isSending = true;
      TicketService.updateTicket(this.form, this.ticket.id)
        .then(
          (send) => {
            console.log(send);
            this.$toast.show({
              title: this.$t('generic-str.success'),
              content: this.$t('warnings.send-component.msg-sent'),
              type: 'success',
            });
            this.form.message = '';
            this.isSending = false;
            this.form.attachments = [];
            this.fetch();
          },
          (error) => {
            this.$toast.show({
              title: this.$t('sms.infos.filters.status.lbl-error'),
              content: error.response.data.message,
              type: 'danger',
            });
            this.isSending = false;
          },
        )
        .catch((error) => {
          console.log(error.response);
          this.isSending = false;
        });
    },
  },
};
</script>

<style scoped lang="scss">
.btn-search-id {
  height: 35px;
}
.btn-export {
  margin-left: 1px;
  padding: 8px 20px !important;
}
.m-top-10 {
  margin-top: 10px;
}
.p-support {
  color: #808080;
  span {
    margin-right: 24px;
  }
}
</style>
